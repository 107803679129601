import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoLockOpenOutline } from "react-icons/io5";
import { FaChartLine, FaCheckCircle, FaRegFileAlt } from "react-icons/fa";
import { BsFiletypeDoc } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { VscEye } from "react-icons/vsc";
import { AiOutlineLineChart } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { RiCouponLine, RiExchange2Line } from "react-icons/ri";
import { LuFlagTriangleRight } from "react-icons/lu";
import { PiGlobeStand } from "react-icons/pi";
import { GrProjects } from "react-icons/gr";
import { GoProjectRoadmap } from "react-icons/go";

function Home() {
  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (card) => {
    setActiveCard((prevActiveCard) => (prevActiveCard === card ? null : card));
  };

  const getCardBackgroundColor = (card) => {
    return activeCard === card ? "rgba(255, 255, 255, 0.1)" : "transparent";
  };

  useEffect(() => {
    const navbar = document.getElementById("navbar");

    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust the scroll position as needed
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // once: true
    });
  }, []); // Run once on component mount

  return (
    <>
      <div>
        <div className="container">
          <nav
            id="navbar"
            className="navbar navbar-expand-lg navbar-dark custom_nav fixed-top"
          >
            <div className="container">
              <a className="navbar-brand" to="/">
                <img
                  src="/images/main-logo.png"
                  alt="logo"
                  className="logoStyle"
                />
              </a>
              <button
                className="navbar-toggler px-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span className="navbar-toggler-icon px-0"></span>
              </button>

              <div
                class="offcanvas offcanvas-start mobile_offcanvas_body"
                tabindex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  <img
                  src="/images/main-logo.png"
                  alt="logo"
                  className="logoStyle"
                />
                <div>
              
                </div>

                  </h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="offcanvas-body align-items-center">
                  <ul class="navbar-nav m-auto mb-lg-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a
                        class="nav-link"
                        href="#about_section"
                      >
                        About Us
                      </a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link" href="#benefit_section">
                        Benefit
                      </a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link" href="#solution_section">
                        Solution
                      </a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link spcl_menu" href="#metagifts_sections">
                        ✨ Get BHASH ✨
                      </a>
                    </li>
                  </ul>

                  <div className="mob_nav">
                    <div className="d-grid">
                      <a
                        className="action_btn"
                        aria-current="page"
                        href="#contact_section"
                      >
                        Contact Us
                        <MdKeyboardArrowRight className="ms-1 fs-5" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="position-relative">
          <div className="bg-top">
            <div className="bg-top-inner">
              <img class="bg-top-img" src="/images/bgImg.png" alt="" />
            </div>
          </div>

          <div className="welcome_area">
            <div className="background-overlay"></div>
            <div className="container_sm position-relative">
              <div className="row spacer align-items-center">
                <div className="col-lg-6 mb-3">
                  <div class="badge_welcome mb-4">Welcome</div>
                  <h1 className="main_heading">Blockhash</h1>
                  <h2>Blockchain-Powered Gift Distribution</h2>
                  <div className="mt-5">
                    <a href="#" className="custom_btn">
                      Find Gifts
                      <MdKeyboardArrowRight className="ms-1 fs-5" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="para">
                    A leading technology company specializing in blockchain gift
                    solutions based on the Blockhash Token (BHASH).
                  </div>
                </div>
              </div>
              <div className="content-fold-img">
                <img
                  class="img-fluid"
                  src="/images/content-fold-img.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row spacer">
              <div className="col-lg-6 hide_on_mobile">
                <div className="content_fold_wrapper" id="about_section">
                  <div className="image_backdrop_wrapper">
                    <div className="image_backdrop_orange"> </div>
                  </div>
                  <div className="image_backdrop_inner">
                    <img
                      src="/images/about-img.png"
                      alt=""
                      className="image_backdrop_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h3 className="sub_title">Blockhash Mission</h3>
                <p className="para">
                  Blockhash company goal is to revolutionize the way people
                  interact with digital assets, ensuring trust and equity in
                  every transaction. Join us as we pave the way for a more
                  transparent and equitable digital future.
                </p>

                <p className="para">
                  With our expertise and innovative approach, we aim to
                  transform the gift industry by leveraging the power of
                  blockchain technology.
                </p>
                <p className="para">
                  Our products and advanced DMT Gift Platform create a secure,
                  transparent, and efficient environment for gifts operations.
                  The Blockhash Token enables non-centralized management of
                  global community-driven Gift pools, ensuring automated and
                  secure payments.
                </p>

                <p className="para">
                  Join us and experience the future of Gift innovation!
                </p>
              </div>
            </div>

            <div className="row spacer">
              <div className="spacer-sm text-center">
                <h3 className="sub_title fs-1" id="benefit_section">
                  Benefits
                </h3>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="custom_card">
                  <div className="card-body">
                    <div className="mb-3">
                      <IoLockOpenOutline className="fs-3" />
                    </div>
                    <h5>Security</h5>
                    <p className="para">
                      With the Blockhash token, participants can quickly recieve
                      gifts from anywhere in the world, enabling greater
                      inclusivity and participation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="custom_card">
                  <div className="card-body">
                    <div className="mb-3">
                      <VscEye  className="fs-3" />
                    </div>
                    <h5>Transparency</h5>
                    <p className="para">
                      With the use of blockchain technology, all gift
                      transactions and outcomes are recorded on a public ledger,
                      providing transparency and eliminating any doubts of
                      manipulation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="custom_card">
                  <div className="card-body">
                    <div className="mb-3">
                    <AiOutlineLineChart  className="fs-3" />
                    </div>
                    <h5>Efficiency</h5>
                    <p className="para">
                      Automating processes through smart contracts will
                      streamline the gifts operations, reducing administrative
                      costs and improving overall efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="custom_card">
                  <div className="card-body">
                    <div className="mb-3">
                      <TbGridDots className="fs-3" />
                    </div>
                    <h5>Accessibility</h5>
                    <p className="para">
                      With the Blockhash token, participants can quickly recieve
                      gifts from anywhere in the world, enabling greater
                      inclusivity and participation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="custom_card">
                  <div className="card-body">
                    <div className="mb-3">
                      <RiExchange2Line className="fs-3" />
                    </div>
                    <h5>Customization</h5>
                    <p className="para">
                      Our solutions offer flexibility, allowing operators to
                      customize their Gifts platform's rules, features, and
                      branding to create a unique and tailored experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="custom_card">
                  <div className="card-body">
                    <div className="mb-3">
                      <LuFlagTriangleRight className="fs-3" />
                    </div>
                    <h5>Trustworthiness</h5>
                    <p className="para">
                      Through the use of decentralized networks and smart
                      contracts, we enhance the trustworthiness of gifts ,
                      ensuring fair and unbiased outcomes.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row spacer align-items-center">
              <div className="text-center m-auto container_sm spacer-sm">
                <h4 className="main_title"> Blockhash Token</h4>
                <p className="para">
                  Join us at DMT and become a part of the future of gifts
                  innovation. Invest in the Blockhash token, participate in
                  exciting gifts, and experience the benefits of blockchain
                  technology in the gifts industry.
                </p>
              </div>
              <div className="col-lg-6 mb-4">
                <h4 className="mb-3">About Blockhash Token</h4>
                <p className="para">
                  The Blockhash token is a next-generation asset gift token that
                  powers our smart contract on the blockchain network. It
                  enables decentralized management of global community-driven
                  asset gift pools, ensuring automated and safe payments for
                  participants. With the BHASH token, we revolutionize the asset
                  gift industry by enhancing security, increasing transparency,
                  and promoting greater accessibility. Participants can enjoy
                  secure and tamper-proof transactions, transparent outcomes
                  recorded on a public ledger, and the ability to participate in
                  asset gifting anywhere in the world.
                </p>

                <a href="#" className="anchor_link">
                  Open White Paper
                </a>
              </div>
              <div className="col-lg-6 mb-4">
                <div className="content_fold_wrapper">
                  <div className="image_backdrop_wrapper">
                    <div className="image_backdrop_green"> </div>
                  </div>
                  <div className="image_backdrop_inner token_img_backdrop">
                    <div>
                      <div className="mb-3  text-center">
                        Buy BHASH on the following platforms
                      </div>

                      <div className="d-flex gap-5 justify-content-center">
                        <div className="token_img_box">
                          <img
                            src="https://framerusercontent.com/images/yauZ5kK4xV6oYzdB80GJlmcfous.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="token_img_box">
                          <img
                            src="https://framerusercontent.com/images/rIW8r05kxSnouCRfbD7Ybtccgb0.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="token_img_box">
                          <img
                            src="https://framerusercontent.com/images/QmbmWLnwaNEIMTE6lfbxFoRFojY.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row spacer align-items-center">
              <div className="col-lg-6 mb-4">
                <div className="content_fold_wrapper">
                  <div className="image_backdrop_wrapper">
                    <div className="image_backdrop_teal"> </div>
                  </div>
                  <div className="image_backdrop_inner">
                    <img
                      src="/images/card-img2.png"
                      alt=""
                      className="image_backdrop_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <h4 className="mb-3">Investment Opportunities</h4>
                <p className="para">
                  Moreover, the BHASH token offers investment opportunities for
                  users. By investing in the BHASH token, users can benefit from
                  the potential growth and success of the Blockhash ecosystem.
                  The token can be bought and sold on listed exchanges,
                  providing liquidity and flexibility for investors. As the
                  popularity of the Blockhash platform grows, the demand for the
                  BHASH token may increase, potentially leading to value
                  appreciation.
                </p>
              </div>
            </div>

            <div className="row spacer align-items-center">
              <div className="text-center m-auto container_sm spacer-sm">
                <h4 className="main_title">Experience</h4>
                <p className="para">
                  Experience the future of the asset gift industry with
                  Blockhash. Contact us today to learn more about our blockchain
                  asset gift solutions and how we can revolutionize your
                  business.
                </p>
              </div>

              <div className="col-lg-12">
                <div className="experience_wrapper">
                  <div className="bgcolor_img_wrapper">
                    <img
                      src="/images/colorful_bg.png"
                      alt=""
                      className="dmt_img_style"
                    />
                  </div>
                  <div className="exp_backdrop_inner">
                    <div className="experience_img_container">
                      <img
                        src="/images/experince_img.png"
                        alt=""
                        className="dmt_img_style"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div
                      className="custom_card custom_card_transparent"
                      onClick={() => handleCardClick("card1")}
                      style={{
                        backgroundColor: getCardBackgroundColor("card1"),
                      }}
                    >
                      <div className="card-body">
                        <div className="mb-3">
                          <PiGlobeStand className="fs-3" />
                        </div>
                        <h5>Launch of BHASH Token</h5>
                        <p className="para">
                          We have successfully launched the BHASH token, a
                          native cryptocurrency that powers our blockchain asset
                          gift solutions. With the BHASH token, participants can
                          enjoy secure and instant transactions, making asset
                          gifting more accessible and inclusive.
                        </p>
                        <div>
                          {" "}
                          <a href="#" className="anchor_link">
                            Open White Paper
                          </a>
                        </div>
                        <div>
                          <a href="#" className="anchor_link">
                            Open Smart Contract Audit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div
                      className="custom_card custom_card_transparent"
                      onClick={() => handleCardClick("card2")}
                      style={{
                        backgroundColor: getCardBackgroundColor("card2"),
                      }}
                    >
                      <div className="card-body">
                        <div className="mb-3">
                          <RiCouponLine className="fs-3" />
                        </div>
                        <h5>Asset Gift Development</h5>
                        <p className="para">
                          We have extensive experience in developing asset gift
                          systems that cater to different scales and audiences.
                          Our expertise allows us to create innovative and
                          engaging experiences, ensuring the success of your
                          asset gift program. Whether you're looking for
                          exclusivity or wide participation, we can tailor an
                          asset gift solution to meet your specific needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div
                      className="custom_card custom_card_transparent"
                      onClick={() => handleCardClick("card3")}
                      style={{
                        backgroundColor: getCardBackgroundColor("card3"),
                      }}
                    >
                      <div className="card-body">
                        <div className="mb-3">
                          <GoProjectRoadmap className="fs-3" />
                        </div>
                        <h5>
                          Consultancy Projects for Companies and National Asset
                          Gift Programs
                        </h5>
                        <p className="para">
                          We have worked on multiple consultancy projects,
                          providing expert guidance and support to companies and
                          national asset gift programs. Our team of specialists
                          has helped clients design and implement their asset
                          gift strategies, ensuring compliance, efficiency, and
                          success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row spacer" id="solution_section">
              <h3 className="main_title mb-3">Blockhash Tech Solutions</h3>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="cs_card">
                  <p className="para_head">Gifts White-Label Solutions</p>
                  <div className="cs_card_bimg">
                    <img src="/images/img1.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-12 mb-3">
                <div className="cs_card gift_dev_card_height">
                  <div>
                    <p className="para_head mb-0">New Gift Development</p>
                    <div className="sub_desc">
                      Get a full-cycle blockchain gift programme team to
                      create your own product
                    </div>
                  </div>
                  <div className="cs_card_bimg2">
                    <img src="/images/img2.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <div className="cs_card gift_dev_card_height">
                  <div>
                    <p className="para_head mb-0">
                      Gifts Migration to Blockchain for Any Scale
                    </p>
                    <div className="sub_desc">
                      Bring the benefits of blockchain technology to your
                      existing Gifts
                    </div>
                  </div>
                  <div className="cs_card_bimg2">
                    <img src="/images/img3.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3  col-md-12 mb-3">
                <div className="cs_card gift_dev_card_height">
                  <div>
                    {" "}
                    <p className="para_head mb-0">Interactive Gifts Add-Ons</p>
                    <div className="sub_desc">
                      Enhance your Gifts platform with new features
                    </div>{" "}
                  </div>
                  <div className="cs_card_bimg4">
                    <img src="/images/gray-logo-bg.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 mb-3">
                <div className="cs_card">
                  <div>
                    {" "}
                    <p className="para_head mb-0">Gifts Consultancy Services</p>
                    <div className="sub_desc">
                      Make it faster with our experienced team
                    </div>
                  </div>
                  <div className="cs_card_bimg5">
                    <img src="/images/img5.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row spacer">
              <div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1. How does the Blockhash (BHASH) token support the
                        asset gift program?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          The Blockhash (BHASH) token powers our asset gift
                          program by enabling secure, decentralized
                          transactions. Each transaction is recorded on the
                          blockchain, ensuring transparency and trust.
                          Participants can use BHASH tokens to create and
                          participate in global asset gift pools, benefiting
                          from automated and tamper-proof processes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. What does it mean that the BHASH token is
                        deflationary?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          The BHASH token is deflationary, meaning its supply
                          decreases over time. This is achieved through
                          mechanisms such as token burns, where a portion of
                          tokens is permanently removed from circulation. This
                          reduction in supply can potentially increase the value
                          of the remaining tokens, benefiting holders.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. How does the tax burning mechanism work for the BHASH
                        token?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          The tax burning mechanism for the BHASH token involves
                          a small fee on each transaction, a portion of which is
                          burned, or permanently removed from circulation. This
                          process reduces the total supply of BHASH tokens,
                          contributing to the deflationary nature of the token
                          and potentially increasing its value over time.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. How is liquidity generation handled for the BHASH
                        token?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Liquidity generation for the BHASH token is
                          facilitated through the allocation of a portion of
                          transaction fees to liquidity pools. These pools
                          provide the necessary liquidity for trading the BHASH
                          token on decentralized exchanges, ensuring that users
                          can buy and sell tokens efficiently and at fair market
                          prices.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#headingFive"
                        aria-expanded="false"
                        aria-controls="headingFive"
                      >
                        5. How are rewards generated for BHASH token holders?
                      </button>
                    </h2>
                    <div
                      id="headingFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Rewards for BHASH token holders are generated through
                          various mechanisms, including staking and
                          participation in asset gift pools. By staking their
                          BHASH tokens, holders can earn additional tokens as
                          rewards. Additionally, participating in asset gift
                          pools can provide opportunities for further rewards,
                          enhancing the overall value proposition for BHASH
                          token holders.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row spacer-lg align-items-center">
              <div className="col-lg-6 mb-4">
                <h4 className="main_title mb-3">Team</h4>
                <p className="para">
                  Our team consists of global experts from various domains, each
                  bringing unique skills and experiences to the table. We have
                  specialists in traditional and online asset gifting, financial
                  markets and trading, blockchain technology, software and
                  design development, marketing, and global business operations.
                  Leveraging our collective expertise, we deliver comprehensive
                  and innovative blockchain asset gift solutions tailored to
                  meet the specific needs of our clients.
                </p>
              </div>
              <div className="col-lg-6">
                <div
                  className="content_fold_wrapper"
                  style={{ height: "400px" }}
                >
                  <div className="image_backdrop_wrapper">
                    <div className="image_backdrop_blue"> </div>
                  </div>
                  <div className="image_backdrop_inner team_img_backdrop">
                    <img
                      src="/images/team.png"
                      alt=""
                      className="image_backdrop_img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row spacer long_content">
              <div className="col-lg-6 mb-4 left_small_content">
                <div class="content_fold_wrapper">
                  <div class="image_backdrop_wrapper">
                    <div class="image_backdrop_multicolor"> </div>
                  </div>
                  <div class="image_backdrop_inner">
                    <img
                      src="/images/platform.png"
                      alt=""
                      class="image_backdrop_img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-4 right_long_content">
                <h4 className="main_title mb-3">Asset Gift Platform</h4>
                <p className="para">
                  We have developed our asset gift platform using BHASH tokens
                  and blockchain technologies. By harnessing blockchain
                  technology, our platform brings a revolutionary change to the
                  asset gift industry, offering a seamless and enjoyable
                  experience for both operators and participants. Advanced
                  Engine
                </p>

                <div className="d-flex gap-2 mb-3">
                  <div>
                    <FaCheckCircle />
                  </div>
                  <div>
                    <p className="para_head mb-1 mt-1">Advance engine</p>
                    <p className="para">
                      Our advanced engine is designed to create a secure,
                      transparent, and efficient platform for asset gift
                      operations. It incorporates cutting-edge technology to
                      ensure the integrity and reliability of the processes,
                      providing a seamless experience for all users.
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 mb-3">
                  <div>
                    <FaCheckCircle />
                  </div>
                  <div>
                    <p className="para_head mb-1 mt-1">Token Integration</p>
                    <p className="para">
                      Integrating the BHASH token into our platform allows
                      participants to enjoy secure and instant transactions.
                      This eliminates the need for traditional payment methods,
                      making the asset gift experience more convenient and
                      efficient for everyone involved. Blockchain Technology
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 mb-3">
                  <div>
                    <FaCheckCircle />
                  </div>
                  <div>
                    <p className="para_head mb-1 mt-1">
                      Blockchain technology{" "}
                    </p>
                    <p className="para">
                      Our platform is built on the foundation of blockchain
                      technology, which revolutionizes the asset gift industry.
                      Leveraging decentralized networks and smart contracts, we
                      ensure transparency, fairness, and trustworthiness in
                      every aspect of the operations. This guarantees a seamless
                      and enjoyable experience for both operators and
                      participants.
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 mb-3">
                  <div>
                    <FaCheckCircle />
                  </div>
                  <div>
                    <p className="para_head mb-1 mt-1">Customizations</p>
                    <p className="para">
                      We understand that every asset gift program is unique, and
                      our platform offers extensive customization options.
                      Operators can define the rules, mechanics, and user
                      interface of their program, allowing them to create a
                      tailored and engaging experience for their participants.
                      Add-Ons
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 mb-3">
                  <div>
                    <FaCheckCircle />
                  </div>
                  <div>
                    <p className="para_head mb-1 mt-1">Gifts Add-Ons</p>
                    <p className="para">
                      We offer a variety of add-ons that enhance the ecosystem.
                      Participants can enjoy a more engaging and dynamic
                      experience, which boosts user engagement and excitement.
                      These add-ons provide additional features and benefits,
                      enriching the overall experience for all users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dmt_coupon_bg_wrapper">
          <div className="dmt_coupon_bg">
            <div className="dmt_coupon_img_wrapper">
              {" "}
              <img
                src="/images/blue_bg_coupon.png"
                alt=""
                className="dmt_img"
              />
            </div>
          </div>
          <div className="dmt_content_wrapper" id="metagifts_sections">
            <div className="dmt_content_wrapper_left">
              <div className="dmt_content_left">
                <h4 className="main_title mb-3">Metagifts</h4>
                <p className="para">
                  Your Ticket to the Future! We're a blockchain-powered platform
                  prioritizing transparency, community, and life-changing wins.
                  Join us and experience the next generation of gifts!
                </p>
                <div className="mt-5">
                  <a href="#" className="custom_btn">
                    Plot Gifts <MdKeyboardArrowRight />
                  </a>
                </div>
              </div>
            </div>
            <div className="dmt_content_wrapper_right">
              <div className="dmt_content_wrapper_right_card">
                <div className="power_img_wrapper">
                  <div className="bgcolor_img_wrapper">
                    <img
                      src="/images/open-gift.png"
                      alt=""
                      className="dmt_img_style"
                    />
                  </div>
                </div>
                <div className="dmt_content_wrapper_right_card_inner">
                  <div className="bgcolor_img_wrapper">
                  <img
                    src="/images/hanging-gifts.png"
                    alt=""
                    className="dmt_img_style"
                  />
                </div>

                  <div className="main_content_wrapper">
                    <div className="bgcolor_img_wrapper">
                      <img
                        src="/images/logo_banner.png"
                        alt=""
                        className="dmt_img_style"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container spacer-lg">
          <div className="text-center">
            <h3 className="main_title"> Blockhash Token Platforms Roadmap</h3>
          </div>

          <div class="timeline">
            <ul>
              <li>
                <div class="content" data-aos="fade-up">
                  <h3 className="timeline_year">2024(Q3)</h3>
                  <div className="events">
                    <p className="event">
                      <span>✔</span> BHASH Coin Development
                    </p>
                    <p className="event">
                      <span>✔</span> Marketing Campaigns and BHASH Token Launch
                    </p>
                    <p className="event">
                      <span>✔</span> First Gifts Launch (NDA)
                    </p>
                    <p className="event">
                      <span>✔</span> "Free ticket" components based on Gifts
                    </p>
                  </div>
                </div>
                {/* <div class="time">
                <h4>February 2018</h4>
              </div> */}
              </li>

              <li>
                <div class="content" data-aos="fade-up" data-aos-delay="300">
                  <h3 className="timeline_year">2024(Q4)</h3>
                  <div className="events">
                    <p className="event">
                      <span>✔</span> Coingecko & CMC Listing
                    </p>
                    <p className="event">
                      <span>✔</span> Cex Lisiting (According volume)
                    </p>
                    <p className="event">
                      <span>✔</span> Trust Wallet & Token Pocket Listing
                    </p>
                    <p className="event">
                      <span>✔</span> Community Adoption Programme
                    </p>
                  </div>
                </div>
                {/* <div class="time">
                <h4>March 2018</h4>
              </div> */}
              </li>

              <li>
                <div class="content" data-aos="fade-up" data-aos-delay="350">
                  <h3 className="timeline_year">2025</h3>
                  <div className="events">
                    <p className="event">
                      <span>✔</span> Blockhash Coin Development
                    </p>
                    <p className="event">
                      <span>✔</span> Tier1 Listing
                    </p>
                    <p className="event">
                      <span>✔</span> Multi chain Operation
                    </p>
                    <p className="event">
                      <span>✔</span> "Free ticket" components based on NFT
                    </p>
                    <p className="event">
                      <span>✔</span> Types with Ecommerece & Others
                    </p>
                    <p className="event">
                      <span>✔</span> "Free ticket" components based on NFT
                    </p>
                  </div>
                </div>
                {/* <div class="time">
                <h4>April 2018</h4>
              </div> */}
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="container">
          <h3 className="main_title text-center mb-4">
            {" "}
            Blockhash Token White Paper
          </h3>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="content_fold_wrapper" style={{ height: "200px" }}>
                <div className="image_backdrop_wrapper">
                  <div className="image_backdrop_pink"> </div>
                </div>
                <div className="image_backdrop_inner whitepaper_content">
                  <FaRegFileAlt className="fs-1" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <p className="para">
                Read our comprehensive white paper to learn more about our
                innovative blockchain asset gift solutions. It provides in-depth
                information about our technology, methodologies, and the
                advantages of using Blockhash for your asset gifting needs.
              </p>
              <div className="mt-5">
                <a href="#" className="custom_btn">
                  Read More <MdKeyboardArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container spacer-lg">
          <div className="heading text-center">Exchanges</div>
          <div className="gredient_divider">
            <div className="gredient_divider_inner"> </div>
          </div>

          <div className="thumb_img_wrapper">
            <div className="thumb_img_box">
              <img
                src="images/apeswap.png"
                alt="apeswap"
                className="img-fluid"
              />
            </div>

            <div className="thumb_img_box">
              <img
                src="/images/pancakeswap.svg"
                alt="pancakeswap"
                className="img-fluid"
              />
            </div>

            <div className="thumb_img_box">
              <img src="images/mexc.svg" alt="" className="img-fluid" />
            </div>

            <div className="thumb_img_box">
              <img src="images/dodo.png" alt="dodo" className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="heading text-center">Statistics</div>
          <div className="gredient_divider">
            <div className="gredient_divider_inner"> </div>
          </div>

          <div className="thumb_img_wrapper">
            <div className="thumb_img_box">
              <img src="/images/geckoterminal.png" alt="geckoterminal" />
            </div>
            <div className="thumb_img_box">
              <img src="/images/poocoin.png" alt="poocoin" />
            </div>
            <div className="thumb_img_box">
              <img src="/images/bscscan.png" alt="bscscan" />
            </div>
            <div className="thumb_img_box">
              <img src="/images/coingecko.png" alt="coingecko" />
            </div>
            <div className="thumb_img_box">
              <img src="/images/coinmarketcap.png" alt="coinmarketcap" />
            </div>
          </div>
        </div>

        <div className="container spacer-lg">
          <div className="container_sm m-auto text-center">
            <h3 className="main_title" id="contact_section">
              CONTACT US
            </h3>
            <p className="para mb-4">
              Contact our experienced team to discuss your asset gifting
              requirements and explore how our blockchain solutions can benefit
              your business. We're here to guide you through the process and
              help elevate your asset gifting program to the next level. For any
              inquiries, email us at{" "}
              <a
                href="mailto:info@bhashcoin.com?subject=Inquiry&body=Hello, I would like to know more about your services."
                className="anchor_link_orange"
              >
                info@bhashcoin.com
              </a>
            </p>
            <a
              href="mailto:info@bhashcoin.com?subject=Inquiry&body=Hello, I would like to know more about your services."
              className="custom_btn"
            >
              Contact us <MdKeyboardArrowRight />
            </a>
          </div>
        </div>

        <div className="container spacer-lg">
          <div className="footer_section">
            <div className="order2">
              <img
                src="/images/main-logo.png"
                alt=""
                className="img-fluid mb-2"
              />
              <div className="copyright">
                Copyright © 2024 Blockhash Finance
              </div>
              <div className="copyright">All rights reserved</div>
            </div>

            <div className="footer_listing">
              <div>
                <div className="footer_heading">Poollotto</div>
                <ul className="footer_bullets">
                  <li>
                    <a href="">About us</a>
                  </li>
                  <li>
                    <a href="">Benefits</a>
                  </li>
                  <li>
                    <a href="">Solutions</a>
                  </li>
                </ul>
              </div>

              <div>
                <div className="footer_heading">Follow us</div>
                <ul className="footer_bullets">
                  <li>
                    <a href="">Twitter</a>
                  </li>
                  <li>
                    <a href="">Telegram</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
