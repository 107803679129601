import logo from './logo.svg';
import './App.css';
import Home from './component/Home';

function App() {
  return (
   
   <div>
   <Home/>
   </div>
    
  );
}

export default App;
